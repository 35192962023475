<template>
  <el-container>
    <el-aside
      :width="isCollapse ? '3%' : '20%'"
      :style="isCollapse ? 'transition: width .5s;' : ''"
    >
      <h1
        :style="
          isCollapse
            ? 'font-size: 0px;transition: font-size .3s;'
            : 'font-size:20px;transition: font-size .3s;'
        "
      >
        考试列表
      </h1>
      <div
        :width="isCollapse ? '0px' : '100%'"
        :style="isCollapse ? 'transition: width .5s;' : ''"
        class="aside-input"
      >
        <el-input
          v-model="searchInfo.name"
          placeholder="输入考试名称"
          prefix-icon="el-icon-search"
          class="myinput"
          clearable
          v-if="!isCollapse"
          @input="getAllExamInfo()"
        ></el-input>
      </div>
      <!-- 关闭二级菜单的按钮 -->
      <i class="el-icon-menu closeBtn1" @click="collapseStatus()"></i>

      <el-menu
        :default-active="activeMenu"
        class="el-menu-vertical-demo main-menu"
        :collapse="isCollapse"
      >
        <!-- 列表区域 -->
        <el-menu-item
          :index="index.toString()"
          v-for="(item, index) in examInfoLists"
          :key="index"
          @click="chooseChecked(item, index.toString())"
        >
          {{ item.name }} + {{ item.subjectName }}
        </el-menu-item>
      </el-menu>
    </el-aside>

    <!-- 展开二级菜单的按钮 -->

    <!-- <i
      class="el-icon-menu closeBtn2" style="width:3%;background:#fff;height:100%;border-radius:10px;text-align:center;margin-right:20rpx"
      v-if="isCollapse"
      @click="collapseStatus()"
    ></i> -->
    <el-main>
      <div class="btndiv">
        <!-- 展开二级菜单的按钮 -->
        <el-button
          icon="el-icon-plus"
          class="hbtn create-examinee"
          :disabled="isDisable"
          @click="openAddGroupDialog()"
          >单个创建</el-button
        >
        <el-button
          icon="el-icon-plus"
          class="hbtn batch-new"
          @click="openAddGroupBatch()"
          >批量新增</el-button
        >
        <el-button
          icon="el-icon-rank"
          class="hbtn allocate-examinee"
          :disabled="isDisable"
          @click="randomStudent()"
          >随机分配考生</el-button
        >
        <el-button
          icon="el-icon-close"
          class="hbtn batch-delete"
          :disabled="isDisable"
          @click="removeGroupBatch()"
          >批量删除</el-button
        >
      </div>

      <div></div>

      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <!-- 头部标题与form表单 -->
          <el-row :gutter="20">
            <!-- span属性指的是占据多少列 -->
            <el-col :span="6">
              <span>监控组列表</span>
            </el-col>
            <el-input
              v-model="queryInfo.groupId"
              placeholder="输入监控组号"
              prefix-icon="el-icon-search"
              clearable
              @input="getGroupPageLists()"
            ></el-input>
          </el-row>
        </div>

        <div class="text item">
          <el-table
            :data="groupLists"
            style="width: 100%"
            stripe
            :max-height="tableHeight"
            @selection-change="selectionChange"
          >
            <el-table-column
              align="center"
              type="selection"
              label="全选"
              width="55"
              fixed
            ></el-table-column>
            <el-table-column
              align="center"
              prop="name"
              label="监控组号"
              min-width="180"
            ></el-table-column>
            <!-- <el-table-column
              align="center"
              prop="name"
              label="监控组名"
              min-width="180"
            ></el-table-column> -->
            <el-table-column
              align="center"
              prop="studentCount"
              label="考试人数"
              sortable
              min-width="180"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="teacherCount"
              label="监考人数"
              sortable
              min-width="180"
            ></el-table-column>
            <el-table-column
              align="center"
              label="操作"
              min-width="280"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  style="background-color: #563279"
                  @click="adjustExaminee(scope.row)"
                  >调整考生</el-button
                >
                <el-button
                  size="mini"
                  style="background-color: #8b2d87"
                  @click="adjustInvigilator(scope.row)"
                  >调整监考</el-button
                >
                <!-- <el-button
                  size="mini"
                  style="background-color:#8B2D87;"
                  @click="openEditGroupDialog(scope.row)"
                  >修改</el-button
                > -->
                <el-button
                  size="mini"
                  style="background-color: #da8309"
                  @click="removeGroup(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <!-- 分页器 -->
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryInfo.pageNum"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            :page-count="totalPage"
          >
          </el-pagination>
        </div>
      </el-card>
    </el-main>

    <!-- 调整监考人员 -->
    <GroupChangeDialog
      :dialogVisibleFu="dialogVisibleFus"
      :dialogText="dialogText"
      :dialogForm="dialogForm"
      :subjectName="subjectName"
      @closeDialog="closeDialog"
    />
    <!-- dialog 弹框 -->
    <!-- 单个监控组新增、更新 -->
    <!-- <GroupDialog
      :dialogVisibleFu="dialogVisibleFu"
      :dialogText="dialogText"
      :selectPeople="selectPeople"
      :dialogForm="dialogForm"
      @closeDialog="closeDialog"
    /> -->
  </el-container>
</template>

<script>
// import GroupDialog from "c/admin/group/GroupDialog";
import GroupChangeDialog from "c/admin/group/GroupChangeDialog";
import {
  getAllExamData,
  getGroupPageData,
  groupSelectPeople,
  addGroup,
  deleteGroup,
  studentRandom,
} from "r/admin/group";
import { Loading } from "element-ui";

export default {
  name: "Lists",
  data() {
    return {
      searchInfo: {
        name: "", // 考试名称
      },
      examId: "", // 考试id
      subjectId: "", // 科目id
      subjectName: "", // 科目名称
      examName: "",
      examName: "",
      queryInfo: {
        subjectUuid: "",
        groupId: "", // 监控组id
        pageNum: 1,
        pageSize: 10,
      },
      total: 0, // 数据总条数
      totalPage: 1, // 总页数
      groupLists: [], // 监控组信息列表
      examInfoLists: [], // 考试管理数据列表
      // 弹框文字
      dialogText: {
        dialogTitle: "",
        btnText: "",
      },
      dialogVisibleFu: false, // 控制dialog弹框显示与隐藏
      dialogVisibleFus: false, // 控制dialog弹框2显示与隐藏
      dialogForm: {}, // 数据对象
      dialogFormRules: {}, // 表单验证规则
      selectData: [], //表格中选中项的数据集合
      selectCount: 0, // 表格中选中项的数量
      ids: [], // 选中的列的id数组集合
      isDisable: false, // 提交按钮是否可点击（防重复点击）

      isCollapse: false, // 是否水平折叠
      activeMenu: "", // 当前激活的二级分类
      tableHeight: "",
      pageFlag: "true",
      menulist: "",
    };
  },

  created() {
    //页面创建时执行一次getHeight进行赋值，顺道绑定resize事件
    window.addEventListener("resize", this.getHeight);
    this.getHeight();

    this.activeMenu = window.sessionStorage.getItem("admin-activeMenu");

    if (this.$route.query.pageflag == "false") {
      console.log(
        JSON.parse(window.sessionStorage.getItem("search-key")).name,
        "返回key值"
      );
      this.searchInfo = JSON.parse(window.sessionStorage.getItem("search-key"));
      this.getAllExamInfo();
      return;
    }

    this.getAllExamInfo();
  },
  methods: {
    // 保存当前激活的分类
    activeHandle(active) {
      window.sessionStorage.setItem("admin-activeMenu", active);
      this.activeMenu = active;
    },

    //定义方法，获取高度减去头尾
    getHeight() {
      this.tableHeight = window.innerHeight - 341 + "px";
    },

    // 获取所有考试信息列表
    async getAllExamInfo() {
      const data = await getAllExamData(this.searchInfo);
      // console.log(this.searchInfo,"搜索关键词")

      //缓存搜索关键词
      window.sessionStorage.setItem(
        "search-key",
        JSON.stringify(this.searchInfo)
      );

      // console.log(data,"获取的考试信息列表");
      if (data.status !== 200) return this.$message.error(res.message);
      // console.log(data,"data")
      this.examInfoLists = data.data;

      // var searchList = this.examInfoLists
      // console.log(searchList,"搜索后数据")
      // window.sessionStorage.setItem("search-menu",JSON.stringify(searchList))

      // 默认考试列表第一项 examId
      this.queryInfo.subjectUuid =
        data.data[this.activeMenu].subjectUuid || data.data[0].subjectUuid;
      this.examId = data.data[this.activeMenu].examId || data.data[0].examId;
      this.subjectId =
        data.data[this.activeMenu].subjectId || data.data[0].subjectId;
      this.subjectName =
        data.data[this.activeMenu].subjectName || data.data[0].subjectName;
      this.examName = data.data[this.activeMenu].name || data.data[0].name;

      // 根据分页查询监控组（input搜索）的方法
      this.getGroupPageLists();
    },

    // 根据分页查询监控组
    async getGroupPageLists() {
      const { data: res } = await getGroupPageData(this.queryInfo);
      if (res.code !== 200) return this.$message.error(res.message);
      this.groupLists = res.data.list;
      this.total = res.data.total;
      this.totalPage = res.data.totalPage;
      // console.log(res.data,"你好年后基金手机打开")
    },

    // 控制每页显示几条数据
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getGroupPageLists();
    },
    // 控制显示第几页
    handleCurrentChange(newNum) {
      this.queryInfo.pageNum = newNum;
      this.getGroupPageLists();
    },
    /*侧边栏部分*/

    // 选择二级菜单，更新表格
    chooseChecked(data, index) {
      // console.log(data,"获取的列表信息");
      this.queryInfo.subjectUuid = data.subjectUuid;
      this.examId = data.examId;
      this.subjectId = data.subjectId;
      this.subjectName = data.subjectName;
      this.examName = data.name;

      this.activeHandle(index);

      this.getGroupPageLists();
    },

    // 关闭弹框
    closeDialog(value) {
      this.dialogVisibleFu = value;
      this.dialogVisibleFus = value;
      // 刷新表格
      this.getGroupPageLists();
    },

    // 单个添加监控组
    async openAddGroupDialog() {
      this.isDisable = true;
      setTimeout(() => {
        this.isDisable = false;
      }, 1000);

      this.dialogForm = {
        examId: this.examId,
        id: 0,
        name: "",
        subjectId: this.subjectId,
        subjectUuid: this.queryInfo.subjectUuid,
      };
      // console.log(this.dialogForm);
      const { data: res } = await addGroup(this.dialogForm);
      // console.log(res);
      if (res.code !== 200) return this.$message.error(res.message);
      this.$message.success(res.data);

      // 刷新监控组列表
      this.getGroupPageLists();
    },

    // 单个删除监控组操作
    async removeGroup(data) {
      const confirmResult = await this.$confirm(
        `确认删除监控组：${data.id} ？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false,
        }
      ).catch((err) => err);

      if (confirmResult !== "confirm")
        return this.$message.info("您取消了删除");

      // 发送请求
      const { data: res } = await deleteGroup({
        ids: data.id,
        examId: this.examId,
        subjectUuid: this.queryInfo.subjectUuid,
      });
      // console.log(res);
      if (res.code !== 200) return this.$message.error(res.message);
      this.$message.success(res.message);

      this.getGroupPageLists();
    },

    // 选中状态改变
    selectionChange(val) {
      // val 指选中的列的数组集合
      // 清空ids数据
      this.selectData = val;
      this.ids = [];
      this.selectCount = val.length;
      val.forEach((item) => {
        // console.log(item.id);
        this.ids.push(item.id);
      });
    },

    // 批量删除监控组操作
    async removeGroupBatch() {
      this.isDisable = true;
      setTimeout(() => {
        this.isDisable = false;
      }, 1000);

      if (this.selectCount <= 0)
        return this.$message.info("您还未选择需要删除的监考人员");

      const confirmResult = await this.$confirm(
        `确认删除所选 ${this.selectCount} 条数据？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false,
        }
      ).catch((err) => err);

      if (confirmResult !== "confirm")
        return this.$message.info("您取消了删除");

      // 发送请求
      const { data: res } = await deleteGroup({
        ids: this.ids.join(","),
        examId: this.examId,
        subjectUuid: this.queryInfo.subjectUuid,
      });
      // console.log(res);
      if (res.code !== 200) return this.$message.error(res.message);
      this.$message.success(res.message);

      this.getGroupPageLists();
    },

    // 打开批量新增监控组弹框
    openAddGroupBatch() {
      this.dialogForm = {
        examId: this.examId,
        id: 0,
        name: "",
        subjectId: this.subjectId,
        subjectUuid: this.queryInfo.subjectUuid,
        pageNum: 1,
        pageSize: 5,
      };
      // console.log("ppp");
      // console.log(this.examId);
      this.dialogVisibleFus = true;
      this.dialogText.dialogTitle = "批量新增";
      this.dialogText.btnText = "确 认";
    },

    // 进入调整监考页面
    adjustInvigilator(data) {
      // console.log(data);

      var result = {
        id: data.id,
        subjectId: data.subjectId,
        subjectUuid: data.subjectUuid,
        examId: data.examId,
        name: data.name,
        examName: this.examName,
        subjectName: this.subjectName,
      };

      this.$router.push({
        name: "AdjustInvigilator",
        query: result,
      });
    },
    // 进入调整考生页面
    adjustExaminee(data) {
      // console.log(data, "调整考生页面返回数据");

      var result = {
        id: data.id,
        subjectId: data.subjectId,
        subjectUuid: data.subjectUuid,
        examId: data.examId,
        name: data.name,
        examName: this.examName,
        subjectName: this.subjectName,
      };
      this.$router.push({
        name: "AdjustExaminee",
        query: result,
      });
    },

    // 控制是否水平折叠收起菜单
    collapseStatus() {
      this.isCollapse = !this.isCollapse;
    },

    // 随机分配考生
    async randomStudent() {
      this.isDisable = true;
      setTimeout(() => {
        this.isDisable = false;
      }, 1000);

      const loading = this.$loading({
        lock: true,
        text: "分配中，请稍后...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      const { data: res } = await studentRandom({
        examId: this.examId,
        subjectId: this.subjectId,
        subjectUuid: this.queryInfo.subjectUuid,
      });
      // console.log(res);

      if (res.code !== 200) {
        loading.close();
        return this.$message.error(res.message);
      }

      loading.close();
      this.$message.success(res.message);
      // 刷新列表
      this.getGroupPageLists();
    },

    // 打开添加监控组弹框
    // openAddGroupDialog() {
    //   this.dialogForm = {
    //     examId: this.searchInfo.examId,
    //     examName: this.searchInfo.examName,
    //     id: "",
    //     name: "",
    //     subjectId: 0,
    //   };
    //   console.log(this.dialogForm);
    //   this.dialogVisibleFu = true;
    //   this.dialogText.dialogTitle = "创建监控组";
    //   this.dialogText.btnText = "创 建";
    // },
    // // 打开修改监控组对话框
    // openEditGroupDialog(data) {
    //   console.log(data);
    //   this.getGroupSelectPeople(data.id);
    //   this.dialogForm = data;
    //   this.dialogVisibleFu = true;
    //   this.dialogText.dialogTitle = "修改监控组";
    //   this.dialogText.btnText = "修 改";
    // },
  },

  components: {
    // GroupDialog,
    GroupChangeDialog,
  },
};
</script>

<style scoped lang="scss">
@import "a/scss/menuitem";
@import "a/scss/contentheader";
@import "a/scss/table";
@import "a/scss/page";
::v-deep .caret-wrapper {
  position: absolute;
  top: -5px;
}
::v-deep .el-select-dropdown__item {
  height: 22px;
  line-height: 22px;
  color: red;
}
</style>
